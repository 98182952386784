import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledHeading = styled.h1`
  color: ${({ theme, color }) =>
    color === "white" ? theme.color.white : theme.color.secondary01};
  ${({ margin }) => margin && `margin: ${margin}`};
  margin: ${({ margin }) => margin || 0};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ extraStyles }) => extraStyles};
`

const H = ({ as, ...props }) => <StyledHeading as={as} {...props} />

H.propTypes = {
  as: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
}

H.defaultProps = {
  as: "h1",
}

export default H
