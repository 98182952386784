import React, { useRef, useEffect } from "react"
import P from "components/atoms/P"
import H from "components/atoms/H"
import PropTypes from "prop-types"
import lottie from "lottie-web"
import Validation from "assets/animations/validation.json"

import {
  StyledWrapper,
  headingStyles,
  errorCodeStyles,
  textStyles,
  StyledAnimation,
} from "./NotFoundSection.styles"

const NotFoundSection = ({
  errorCode,
  title,
  contentText,
  children,
  isSection,
}) => {
  const animationContainer = useRef(null)

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: Validation,
    })
    return () => anim.destroy()
  }, [])

  return (
    <StyledWrapper isSection={isSection}>
      {errorCode && (
        <H as="h1" extraStyles={errorCodeStyles}>
          {errorCode}
        </H>
      )}
      <H as="h2" extraStyles={headingStyles}>
        {title}
      </H>
      <P extraStyles={textStyles}>{contentText}</P>
      <StyledAnimation ref={animationContainer} />
      {children}
    </StyledWrapper>
  )
}

NotFoundSection.propTypes = {
  errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  children: PropTypes.node,
  isSection: PropTypes.bool,
}

NotFoundSection.defaultProps = {
  errorCode: "",
  children: null,
  isSection: false,
}

export default NotFoundSection
