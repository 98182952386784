import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import browserWindow from "utils/browserWindow"

import Layout from "components/molecules/Layout"
import SEO from "components/molecules/SEO"
import NotFoundSection from "components/content/NotFoundSection"
import Button from "components/atoms/Button"

const UnderConstructionPage = ({ pageContext: { language } }) => {
  const { formatMessage } = useIntl()
  const goBack = () => browserWindow.history.go(-1)

  return (
    <Layout language={language}>
      <SEO title="Under Construction" />
      <NotFoundSection
        title={formatMessage({ id: "pages.underConstruction.heading" })}
        contentText={formatMessage({
          id: "pages.underConstruction.subHeading",
        })}
      >
        <Button onClick={goBack} data-testid="underConstructionButton">
          {formatMessage({ id: "pages.underConstruction.redirect" })}
        </Button>
      </NotFoundSection>
    </Layout>
  )
}

UnderConstructionPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default UnderConstructionPage
