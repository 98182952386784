import styled, { css } from "styled-components"

const headingStyles = css`
  line-height: 1.25;
  max-width: 34rem;

  ${({ theme }) => theme.mq.medium} {
    font-size: 4.8rem;
    max-width: initial;
  }
`
const errorCodeStyles = css`
  font-size: 7.2rem;
  line-height: 1.11;
  color: ${({ theme }) => theme.color.secondary05};
`
const textStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
  line-height: 1.78;
  margin: 4rem 0 6.8rem;
  max-width: 19em;

  ${({ theme }) => theme.mq.medium} {
    margin: 2rem 0 3.2rem;
    text-align: center;
    max-width: 28em;
  }
`
const StyledAnimation = styled.div`
  width: 100%;
  max-width: 37.5rem;
  height: 30.7rem;
  margin-bottom: 4.8rem;

  ${({ theme }) => theme.mq.medium} {
    max-width: 46rem;
    height: 28rem;
  }
`
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.gridWidth};
  padding: 13.6rem ${({ theme }) => theme.layout.mobilePadding} 6.4em;

  ${({ theme }) => theme.mq.medium} {
    padding: 16.8rem ${({ theme }) => theme.layout.mobilePadding} 9.6rem;
    align-items: center;

    ${({ isSection }) =>
      isSection &&
      css`
        padding: 1.6rem ${({ theme }) => theme.layout.mobilePadding} 0;
      `}
  }
`

export {
  StyledWrapper,
  headingStyles,
  errorCodeStyles,
  textStyles,
  StyledAnimation,
}
